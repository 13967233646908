import moment, { Moment } from "moment";
import { useMemo } from "react";
import {
  LATE_CAR_SECONDS,
  NIGHTCAR_SECONDS,
} from "../reports/useTimetables_ByDate";
import { ElectroRosterDriver } from "./types/ElectroRoster";
import { RosterDriver } from "./types/Roster";

function formatSeconds(seconds: number, quota: number) {
  const minutes = Math.round((seconds - quota * 3600) / 60);
  const posMinutes = Math.abs(minutes);
  const hours = Math.floor(posMinutes / 60);
  return `${minutes < 0 ? "-" : ""}${hours.toString().padStart(2, "0")}:${(
    posMinutes % 60
  )
    .toString()
    .padStart(2, "0")}`;
}

export function isLateCar(
  endTime: string | Moment | null,
  shiftType: 1 | 2 | null,
  selectedDate: Moment,
  dayDiff = 1
) {
  const end_time = typeof endTime === "string" ? moment(endTime) : endTime;

  if (!end_time || !end_time.isValid()) {
    return false;
  } else {
    return end_time.isSameOrAfter(
      selectedDate
        .clone()
        .startOf("day")
        .subtract(dayDiff, "day")
        .add(shiftType === 2 ? NIGHTCAR_SECONDS : LATE_CAR_SECONDS, "seconds")
    );
  }
}

function getOldShiftText(
  date: string,
  task: {
    readonly linename: string;
    readonly car_no: number | null;
    readonly shift_type: 1 | 2 | null;
    readonly start_time: string;
    readonly end_time: string;
    readonly work_seconds: number;
  }
) {
  const startTime = task.start_time ? moment(task.start_time) : null;
  const endTime = task.end_time ? moment(task.end_time) : null;

  const time =
    startTime &&
      endTime &&
      startTime.isValid() &&
      endTime.isValid() &&
      !startTime.isSame(endTime)
      ? ` [${startTime.format("HH:mm")} - ${endTime.format("HH:mm")}]`
      : "";

  if (task.car_no && task.car_no > 0) {
    const lateCarSuffix =
      task.end_time &&
        isLateCar(
          endTime,
          task.shift_type,
          moment(date, "DD.MM.YYYY"),
          0
          //tIdx + 1
        )
        ? " (късна кола)"
        : "";

    return `${task.linename}/${task.car_no}${lateCarSuffix}${time}`;
  } else {
    return `${task.linename}${time}`;
  }
}

export function useDriverItemTitle(
  driver: RosterDriver | ElectroRosterDriver,
  driverComment: string | null,
  isFirstOfMonth: boolean
) {
  const driverTitle = useMemo(() => {
    return [
      `Водач №: ${driver.sl_number}`,
      `Име: ${driver.name}`,
      driver.preferred_vehicle ? `ПС: ${driver.preferred_vehicle}` : null,
      driver.vehicletypes_skills.map((s) => `Способности: ${s.description}`) ||
      [],
      `Извънреден труд до момента: ${isFirstOfMonth
        ? "0"
        : formatSeconds(driver.total_workseconds, driver.total_quota)
      }`,
      driver.intershift_times.length > 0
        ? `Междусменни почивки: ${driver.intershift_times.map((it) => formatSeconds(
          it.time,
          0
        )).join(", ")}`
        : null,
      "Задачи за предходни дни:",
      driver.old_daytasks.map((odt) => {
        if (odt.tasks.length === 0) {
          return `    ${odt.date}: Няма задача`;
        } else {
          return `    ${odt.date}: ${odt.tasks
            .map((t) => getOldShiftText(odt.date, t))
            .join(", ")}`;
        }
      }),
      driver.roster_issues && driver.roster_issues.length > 0
        ? [
          "---",
          "Открити проблеми:",
          ...driver.roster_issues.map((ri) => `    • ${ri.description}`),
        ]
        : [],
      driverComment ? `Коментар: ${driverComment}` : null,
      driver.comment && driver.comment.trim() !== ""
        ? `Коментар в графика: ${driver.comment}`
        : null,
    ]
      .flat()
      .filter((l) => l && l.trim() !== "")
      .join("\n");
  }, [
    driver.sl_number,
    driver.name,
    driver.preferred_vehicle,
    driver.vehicletypes_skills,
    driver.total_workseconds,
    driver.total_quota,
    driver.intershift_times,
    driver.old_daytasks,
    driver.roster_issues,
    driver.comment,
    isFirstOfMonth,
    driverComment,
  ]);

  return driverTitle;
}
