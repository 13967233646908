import { useCallback, useState } from "react";
import { ReportPrinter } from "./ReportPrinter";
import companyLogoPng from "./company-logo.png";
import electroLogoPng from "./electro-logo.png";
import { FontStyle, RowInput } from "jspdf-autotable";
import moment from "moment";
import { RosterShift, RosterVehicle } from "../pages/roster/types/Roster";
import { useSelector } from "react-redux";
import { RootState } from "../data/store";
import { useDepotFilter } from "../filters/useDepotFilter";
import { Transport } from "../data/api/types/driver";
import { ScheduleDriver } from "../data/api/types/schedule";
import { useTransportFilter } from "../filters/useTransportFilter";
import { PageData, usePrintJourneyListData } from "./usePrintJourneyListData";
import {
  JourneyListSummary,
  processJourneyListSummary,
} from "./printJourneyListHelpers";
import {
  GarageSettings,
  getSettingsByGarage,
  useGarageSettings,
} from "../common/useGarageSettings";

//function u32(buf: Uint8Array, o: number) {
//  return (buf[o] << 24) | (buf[o + 1] << 16) | (buf[o + 2] << 8) | buf[o + 3];
//}
//
//function size(buf: Uint8Array) {
//  return {
//    width: u32(buf, 16),
//    height: u32(buf, 16 + 4),
//  };
//}

function printSerialNumber(printer: ReportPrinter, page: PageData) {
  printer.setFont("slab-light", 28, 1);
  printer.printTextExt(
    "ПЪТЕН ЛИСТ",
    printer.pageMarginX + printer.printableWidth / 2,
    122,
    printer.printableWidth,
    { align: "center" }
  );

  printer.setFont("slab-light", 15, 1);
  printer.printSpace(-4);
  printer.printText(
    `СЕРИЯ:  ${page.depotText}  №  ${page.serialNumber}`,
    printer.pageMarginX + printer.printableWidth / 2,
    printer.printableWidth,
    { align: "center" }
  );

  printer.printSpace(-5);
}

const logoCache: {
  data: Uint8Array | null;
  type: string | null;
  size: {
    width: number;
    height: number;
  } | null;
} = {
  data: null,
  type: null,
  size: null,
};

async function printLogo(printer: ReportPrinter, selectedTransport: Transport) {
  if (
    logoCache.data === null ||
    logoCache.type === null ||
    logoCache.size === null
  ) {
    const logoRequest = await fetch(
      selectedTransport === "TM" || selectedTransport === "TB"
        ? electroLogoPng
        : companyLogoPng
    );
    const logo = await logoRequest.blob();
    //if (logo.type !== "image/png") {
    //  throw new Error(`Unsupported image format: ${logo.type}`);
    //}
    const logoBuffer = await logo.arrayBuffer();
    const logoData = new Uint8Array(logoBuffer);
    //const imageSize = size(logoData);
    const imageInfo = printer.doc.getImageProperties(logoData);

    logoCache.data = logoData;
    logoCache.type = logo.type;
    //logoCache.size = imageSize;
    logoCache.size = { width: imageInfo.width, height: imageInfo.height };
  }

  if (selectedTransport === "A") {
    printer.doc.addImage(
      logoCache.data,
      logoCache.type,
      printer.pageMarginX,
      printer.pageMarginY - 6,
      (printer.printableWidth * 2) / 3,
      ((printer.printableWidth * 2) / 3) *
      (logoCache.size.height / logoCache.size.width)
    );
  } else {
    printer.doc.addImage(
      logoCache.data,
      logoCache.type,
      printer.pageMarginX * 2,
      printer.pageMarginY - 6,
      (printer.printableWidth * 2) / 9,
      ((printer.printableWidth * 2) / 9) *
      (logoCache.size.height / logoCache.size.width)
    );
  }

  return {
    imageWidth: logoCache.size.width,
    imageHeight: logoCache.size.height,
    addressOffsetX:
      selectedTransport === "A"
        ? (printer.printableWidth * 2) / 3
        : (printer.printableWidth * 2) / 9 + printer.pageMarginX,
    addressOffsetY:
      selectedTransport === "A"
        ? ((printer.printableWidth * 2) / 3) *
        (logoCache.size.height / logoCache.size.width)
        : ((printer.printableWidth * 2) / 9) *
        (logoCache.size.height / logoCache.size.width) +
        10,
  };
}

function printDrivers(printer: ReportPrinter, page: PageData, width: number) {
  let maxWidth = width;
  let tsize: {
    width: number;
    height: number;
  };

  if (!page.isExtraDriver) {
    tsize = printer.printText("Водач I смяна:", printer.pageMarginX * 2);
    maxWidth = Math.max(maxWidth, tsize.width);

    tsize = printer.printText("Водач II смяна:", printer.pageMarginX * 2);
    maxWidth = Math.max(maxWidth, tsize.width);
  } else {
    tsize = printer.printText("Водач  ......  смяна:", printer.pageMarginX * 2);
    maxWidth = Math.max(maxWidth, tsize.width);

    tsize = printer.printText("Водач  ......  смяна:", printer.pageMarginX * 2);
    maxWidth = Math.max(maxWidth, tsize.width);
  }

  if ((page.isSpecial || page.drivers.shift3) && !page.isExtraDriver) {
    tsize = printer.printText("Водач III смяна:", printer.pageMarginX * 2);
  } else if (
    page.drivers.shift1?.nastavnikId ||
    page.drivers.shift2?.nastavnikId ||
    page.drivers.shift3?.nastavnikId
  ) {
    tsize = printer.printText("Наставници:", printer.pageMarginX * 2);
  } else {
    tsize = printer.printText("Придружители:", printer.pageMarginX * 2);
  }
  maxWidth = Math.max(maxWidth, tsize.width);

  printer.doc.line(
    printer.pageMarginX * 2 + maxWidth + 5,
    printer.currentPageY - tsize.height * 4,
    printer.printableWidth,
    printer.currentPageY - tsize.height * 4
  );
  printer.doc.line(
    printer.pageMarginX * 2 + maxWidth + 5,
    printer.currentPageY - tsize.height * 3,
    printer.printableWidth,
    printer.currentPageY - tsize.height * 3
  );
  printer.doc.line(
    printer.pageMarginX * 2 + maxWidth + 5,
    printer.currentPageY - tsize.height * 2,
    printer.printableWidth,
    printer.currentPageY - tsize.height * 2
  );
  printer.doc.line(
    printer.pageMarginX * 2 + maxWidth + 5,
    printer.currentPageY - tsize.height,
    printer.printableWidth,
    printer.currentPageY - tsize.height
  );

  printer.withFont(
    () => {
      if (page.drivers.shift1) {
        printer.doc.text(
          page.drivers.shift1.name,
          printer.pageMarginX * 2 + maxWidth + 10,
          printer.currentPageY - 3 * tsize.height - 2
        );
      }

      if (page.drivers.shift2) {
        printer.doc.text(
          page.drivers.shift2.name,
          printer.pageMarginX * 2 + maxWidth + 10,
          printer.currentPageY - 2 * tsize.height - 2
        );
      }

      if (
        !((page.isSpecial || page.drivers.shift3) && !page.isExtraDriver) &&
        (page.drivers.shift1?.nastavnikId ||
          page.drivers.shift2?.nastavnikId ||
          page.drivers.shift3?.nastavnikId)
      ) {
        const nastavnikTexts: string[] = [];

        if (page.drivers.shift1?.nastavnikId) {
          if (page.drivers.shift1.nastavnikName) {
            nastavnikTexts.push(
              `I-ва см.: ${page.drivers.shift1.nastavnikName} (${page.drivers.shift1.nastavnikId})`
            );
          } else {
            nastavnikTexts.push(`I-ва см.: ${page.drivers.shift1.nastavnikId}`);
          }
        }
        if (page.drivers.shift2?.nastavnikId) {
          if (page.drivers.shift2.nastavnikName) {
            nastavnikTexts.push(
              `II-ра см.: ${page.drivers.shift2.nastavnikName} (${page.drivers.shift2.nastavnikId})`
            );
          } else {
            nastavnikTexts.push(
              `II-ра см.: ${page.drivers.shift2.nastavnikId}`
            );
          }
        }
        // if (page.drivers.shift3?.nastavnikId) {
        //   if (page.drivers.shift3.nastavnikName) {
        //     nastavnikTexts.push(
        //       `III-та см.: ${page.drivers.shift3.nastavnikName} (${page.drivers.shift3.nastavnikId})`
        //     );
        //   } else {
        //     nastavnikTexts.push(
        //       `III-та см.: ${page.drivers.shift3.nastavnikId}`
        //     );
        //   }
        // }

        printer.doc.text(
          nastavnikTexts.join(";   "),
          printer.pageMarginX * 2 + maxWidth + 10,
          printer.currentPageY - 1 * tsize.height - 2
        );
      } else if (page.drivers.shift3) {
        printer.doc.text(
          page.drivers.shift3.name,
          printer.pageMarginX * 2 + maxWidth + 10,
          printer.currentPageY - 1 * tsize.height - 2
        );
      }
    },
    "slab-normal",
    12,
    1
  );

  printer.withFont(
    () => {
      if (page.drivers.shift1) {
        printer.doc.text(
          page.drivers.shift1.sl_number,
          printer.pageMarginX * 2 + 500,
          printer.currentPageY - 3 * tsize.height - 2
        );

        printer.doc.text(
          page.drivers.shift1.lines,
          printer.printableWidth,
          printer.currentPageY - 3 * tsize.height - 2,
          { align: "right" }
        );
      }

      if (page.drivers.shift2) {
        printer.doc.text(
          page.drivers.shift2.sl_number,
          printer.pageMarginX * 2 + 500,
          printer.currentPageY - 2 * tsize.height - 2
        );

        printer.doc.text(
          page.drivers.shift2.lines,
          printer.printableWidth,
          printer.currentPageY - 2 * tsize.height - 2,
          { align: "right" }
        );
      }

      if (page.drivers.shift3) {
        printer.doc.text(
          page.drivers.shift3.sl_number,
          printer.pageMarginX * 2 + 500,
          printer.currentPageY - 1 * tsize.height - 2
        );

        printer.doc.text(
          page.drivers.shift3.lines,
          printer.printableWidth,
          printer.currentPageY - 1 * tsize.height - 2,
          { align: "right" }
        );
      }
    },
    "slab-bold",
    16,
    1
  );
}

async function printPageOne(
  printer: ReportPrinter,
  page: PageData,
  selectedTransport: Transport
) {
  const { addressOffsetX, addressOffsetY } = await printLogo(
    printer,
    selectedTransport
  );

  printer.setFont("slab-light", 10, 1);
  const textSize = printer.printTextExt(
    "Разрешил излизането:",
    (printer.printableWidth * 2) / 3 + 1.5 * printer.pageMarginX + 3,
    42.5,
    "unlimited"
  );
  printer.doc.line(
    (printer.printableWidth * 2) / 3 +
    1.5 * printer.pageMarginX +
    textSize.width +
    5,
    45,
    printer.printableWidth,
    45
  );

  printer.setFont("slab-light", 10, 1);
  printer.printTextExt(
    page.depotAddress,
    printer.pageMarginX + addressOffsetX / 2 + 45,
    printer.pageMarginY + addressOffsetY - 3,
    (printer.printableWidth * 2) / 3,
    { align: "center" }
  );

  if (page.comments.length > 0) {
    const lineHeight = 12;
    const commentMiddle = 95;
    const underlineOffset = 3;
    const offsetRight = 25;
    const maxWidth = 215;

    printer.withFont(
      () => {
        // const lines: string[] = [];
        let totalHeight = 0;
        for (const line of page.comments) {
          const dims = printer.getDimensions(line, {
            maxWidth: maxWidth,
          });
          totalHeight += dims.h;
          // console.log("Line dimensions:", line, dims);
        }

        //const totalHeight = page.comments.length * lineHeight * 1.5;

        let currentLine = Math.round(
          commentMiddle - totalHeight / 2 + lineHeight * 0.75
        );

        for (const comment of page.comments) {
          const tsize = printer.printTextExt(
            comment,
            printer.printableWidth + printer.pageMarginX - offsetRight,
            currentLine,
            maxWidth,
            { align: "right", maxWidth: maxWidth }
          );

          let currentLinePos = currentLine;
          while (currentLinePos < currentLine + tsize.height) {
            printer.doc.line(
              printer.printableWidth + printer.pageMarginX - (offsetRight - 2),
              currentLinePos + underlineOffset,
              printer.printableWidth +
              printer.pageMarginX -
              (offsetRight + 2) -
              tsize.width,
              currentLinePos + underlineOffset
            );
            currentLinePos += lineHeight * 1.5;
          }

          // currentLine += lineHeight * 1.5;
          currentLine += tsize.height;
        }
      },
      "slab-normal",
      lineHeight,
      1.5
    );
  }

  printSerialNumber(printer, page);

  printer.setFont("slab-light", 10, 1.6);
  printer.doc.setLineDashPattern([1, 3], 0);
  printer.printSpace(15);
  let tsize = printer.printText("Автомобил марка:", printer.pageMarginX * 2);

  if (page.vehicle) {
    const mtsize = printer.withFont(
      () => {
        printer.doc.text(
          page.vehicle?.type || "",
          printer.pageMarginX * 2 + tsize.width + 10,
          printer.currentPageY - tsize.height - 2
        );
        const size = printer.getWidth(page.vehicle?.type || "");

        if (page.vehicle?.dk_no) {
          printer.doc.text(
            page.vehicle.dk_no,
            printer.printableWidth,
            printer.currentPageY - tsize.height - 2,
            { align: "right" }
          );
        }

        return size;
      },
      "slab-normal",
      12,
      1
    );

    if (page.vehicle && page.vehicle2_id) {
      printer.withFont(
        () => {
          printer.doc.text(
            `${page.vehicle?.vehicle_id.toString() || ""}  /  ${page.vehicle2_id || ""
            }`,
            printer.pageMarginX * 2 + tsize.width + Math.max(320, mtsize + 200),
            printer.currentPageY - tsize.height - 2
          );
        },
        "slab-bold",
        18,
        1
      );
    } else {
      printer.withFont(
        () => {
          printer.doc.text(
            page.vehicle?.vehicle_id.toString() || "",
            printer.pageMarginX * 2 + tsize.width + Math.max(320, mtsize + 200),
            printer.currentPageY - tsize.height - 2
          );
        },
        "slab-bold",
        18,
        1
      );
    }
  }

  printDrivers(printer, page, tsize.width);

  printer.printSpace(0);
  const nameTSize = printer.printText(
    `${moment().format("DD.MM.YYYY")}  Издал пътния лист:`,
    printer.pageMarginX * 2
  );
  if (page.userNames && page.userNames.trim().length > 0) {
    printer.withFont(
      () => {
        printer.printTextExt(
          page.userNames,
          printer.pageMarginX * 2 + nameTSize.width + 15,
          printer.currentPageY - nameTSize.height + 1
        );
      },
      "slab-bold",
      11,
      1
    );
  }

  printer.printSpace(3);
  const currentY = printer.currentPageY;
  tsize = printer.printTextExt("Подпис:", printer.pageMarginX * 2, currentY);
  printer.doc.line(
    printer.pageMarginX * 2 + tsize.width + 5,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 300,
    printer.currentPageY - tsize.height
  );
  tsize = printer.printTextExt(
    `Време на излизане от ${selectedTransport === "A" ? "гаража" : "депото"}:`,
    printer.pageMarginX * 2 + 320,
    currentY
  );
  let firstShiftStart = printer.pageMarginX * 2 + 320 + tsize.width + 5;
  const tsize1 = printer.printTextExt("I см.:", firstShiftStart, currentY);
  printer.doc.line(
    firstShiftStart + tsize1.width + 5,
    printer.currentPageY - tsize1.height,
    firstShiftStart + 90,
    printer.currentPageY - tsize1.height
  );
  const tsize2 = printer.printTextExt(
    "II см.:",
    firstShiftStart + 100,
    currentY
  );
  printer.doc.line(
    firstShiftStart + tsize2.width + 105,
    printer.currentPageY - tsize2.height,
    firstShiftStart + 190,
    printer.currentPageY - tsize2.height
  );
  const tsize3 = printer.printTextExt(
    "III см.:",
    firstShiftStart + 200,
    currentY
  );
  printer.doc.line(
    firstShiftStart + tsize3.width + 205,
    printer.currentPageY - tsize3.height,
    printer.printableWidth,
    printer.currentPageY - tsize3.height
  );

  //console.log("print times:", shift1Times, shift2Times, shift3Times);

  printer.withFont(
    () => {
      if (page.drivers.shift1?.shiftTimes?.exitTime) {
        printer.doc.text(
          page.drivers.shift1.shiftTimes.exitTime.format("HH:mm"),
          firstShiftStart + tsize1.width + 15,
          printer.currentPageY - tsize1.height - 2
        );
      }

      if (page.drivers.shift2?.shiftTimes?.exitTime) {
        printer.doc.text(
          page.drivers.shift2.shiftTimes.exitTime.format("HH:mm"),
          firstShiftStart + tsize2.width + 115,
          printer.currentPageY - tsize2.height - 2
        );
      }

      if (page.drivers.shift3?.shiftTimes?.exitTime) {
        printer.doc.text(
          page.drivers.shift3.shiftTimes.exitTime.format("HH:mm"),
          firstShiftStart + tsize3.width + 215,
          printer.currentPageY - tsize3.height - 2
        );
      }
    },
    "slab-bold",
    13,
    1
  );

  tsize = printer.printText(
    `Време на прибиране в ${selectedTransport === "A" ? "гаража" : "депото"}:`,
    printer.pageMarginX * 2 + 320
  );

  printer.doc.line(
    firstShiftStart + tsize1.width + 5,
    printer.currentPageY - tsize1.height,
    firstShiftStart + 90,
    printer.currentPageY - tsize1.height
  );
  printer.doc.line(
    firstShiftStart + tsize2.width + 105,
    printer.currentPageY - tsize2.height,
    firstShiftStart + 190,
    printer.currentPageY - tsize2.height
  );
  printer.doc.line(
    firstShiftStart + tsize3.width + 205,
    printer.currentPageY - tsize3.height,
    printer.printableWidth,
    printer.currentPageY - tsize3.height
  );

  printer.withFont(
    () => {
      if (page.drivers.shift1?.shiftTimes?.enterTime) {
        printer.doc.text(
          page.drivers.shift1.shiftTimes.enterTime.format("HH:mm"),
          firstShiftStart + tsize1.width + 15,
          printer.currentPageY - tsize1.height - 2
        );
      }

      if (page.drivers.shift2?.shiftTimes?.enterTime) {
        printer.doc.text(
          page.drivers.shift2.shiftTimes.enterTime.format("HH:mm"),
          firstShiftStart + tsize2.width + 115,
          printer.currentPageY - tsize2.height - 2
        );
      }

      if (page.drivers.shift3?.shiftTimes?.enterTime) {
        printer.doc.text(
          page.drivers.shift3.shiftTimes.enterTime.format("HH:mm"),
          firstShiftStart + tsize3.width + 215,
          printer.currentPageY - tsize3.height - 2
        );
      }
    },
    "slab-bold",
    13,
    1
  );

  printer.printSpace(-10);
  printer.doc.setLineDashPattern([], 0);
  const line1Y = printer.currentPageY;
  printer.doc.line(
    printer.pageMarginX * 2,
    line1Y,
    printer.printableWidth,
    line1Y
  );

  printer.printSpace(13);
  tsize = printer.printText(
    "Автомобилът е технически изправен.",
    printer.pageMarginX * 2
  );
  printer.printTextExt(
    "Приех автомобила технически изправен.",
    printer.pageMarginX * 2 + printer.printableWidth / 2,
    printer.currentPageY - tsize.height
  );

  printer.printSpace(3);
  tsize = printer.printText("Механик:", printer.pageMarginX * 2);
  printer.doc.setLineDashPattern([1, 3], 0);
  printer.doc.line(
    printer.pageMarginX * 2 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.pageMarginX + printer.printableWidth / 2 - 30,
    printer.currentPageY - tsize1.height
  );
  printer.withFont(
    () => {
      printer.doc.text(
        "(фамилия, подпис)",
        printer.pageMarginX * 2 +
        tsize.width +
        2 +
        (printer.pageMarginX +
          printer.printableWidth / 2 -
          30 -
          (printer.pageMarginX * 2 + tsize.width + 5)) /
        2,
        printer.currentPageY - tsize.height / 2,
        { align: "center" }
      );
    },
    "slab-light",
    8
  );
  printer.printSpace(-tsize.height);

  tsize = printer.printText(
    selectedTransport === "A" ? "Водач:" : "Водачи:",
    printer.pageMarginX * 2 + printer.printableWidth / 2
  );
  printer.doc.setLineDashPattern([1, 3], 0);
  printer.doc.line(
    printer.pageMarginX * 2 + printer.printableWidth / 2 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.printableWidth,
    printer.currentPageY - tsize1.height
  );
  if (selectedTransport === "A") {
    printer.withFont(
      () => {
        printer.doc.text(
          "(фамилия, подпис)",
          printer.pageMarginX * 2 +
          printer.printableWidth / 2 +
          tsize.width +
          2 +
          (printer.printableWidth -
            (printer.pageMarginX * 2 +
              printer.printableWidth / 2 +
              tsize.width +
              2)) /
          2,
          printer.currentPageY - tsize.height / 2,
          { align: "center" }
        );
      },
      "slab-light",
      8
    );
  } else {
    const center =
      printer.pageMarginX * 2 +
      printer.printableWidth / 2 +
      tsize.width +
      2 +
      (printer.printableWidth -
        (printer.pageMarginX * 2 +
          printer.printableWidth / 2 +
          tsize.width +
          2)) /
      2;
    printer.doc.text("/", center, printer.currentPageY - tsize.height - 2, {
      align: "center",
    });
    printer.withFont(
      () => {
        printer.doc.text(
          "(I см. - фамилия, подпис)                          (II см. - фамилия, подпис)",
          center,
          printer.currentPageY - tsize.height / 2,
          { align: "center" }
        );
      },
      "slab-light",
      8
    );
  }

  printer.printSpace(-3);
  printer.doc.setLineDashPattern([], 0);
  printer.doc.line(
    printer.pageMarginX + printer.printableWidth / 2,
    line1Y,
    printer.pageMarginX + printer.printableWidth / 2,
    printer.currentPageY
  );
  printer.doc.line(
    printer.pageMarginX * 2,
    printer.currentPageY,
    printer.printableWidth,
    printer.currentPageY
  );
  const line2Y = printer.currentPageY;

  printer.printSpace(13);
  if (selectedTransport === "A") {
    tsize = printer.printText(
      "Проба за алкохол. Общо здравословно състояние - добро.",
      printer.pageMarginX * 2
    );

    tsize = printer.printTextExt(
      "Водачът е годен да управлява.",
      printer.pageMarginX * 2 + printer.printableWidth / 2,
      printer.currentPageY - tsize.height
    );
  } else {
    // tsize = printer.getSize(
    //   "Проба за алкохол. Общо здравословно състояние - добро."
    // );
    // printer.printSpace(tsize.height);
    tsize = printer.printText("Проба за алкохол.", printer.pageMarginX * 2);

    tsize = printer.printTextExt(
      "Общо здравословно състояние - добро.",
      printer.pageMarginX * 2 + printer.printableWidth / 2,
      printer.currentPageY - tsize.height
    );

    tsize = printer.printTextExt(
      "Водачът е годен да управлява.",
      printer.pageMarginX * 2 + printer.printableWidth / 2,
      printer.currentPageY
    );

    printer.printSpace(-tsize.height);
  }

  printer.doc.setLineDashPattern([1, 3], 0);
  tsize = printer.printText("ИЗЛИЗАНЕ", printer.pageMarginX * 2);
  firstShiftStart = printer.pageMarginX * 2 + tsize.width + 30;
  tsize = printer.printTextExt(
    "I  СМЯНА:",
    firstShiftStart,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    firstShiftStart + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    firstShiftStart + 125,
    printer.currentPageY - tsize1.height
  );
  tsize = printer.printTextExt(
    "II  СМЯНА:",
    firstShiftStart + 135,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    firstShiftStart + 135 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.pageMarginX + printer.printableWidth / 2 - 15,
    printer.currentPageY - tsize1.height
  );

  tsize = printer.printText("ПРИБИРАНЕ", printer.pageMarginX * 2);
  tsize = printer.printTextExt(
    "I  СМЯНА:",
    firstShiftStart,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    firstShiftStart + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    firstShiftStart + 125,
    printer.currentPageY - tsize1.height
  );
  tsize = printer.printTextExt(
    "II  СМЯНА:",
    firstShiftStart + 135,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    firstShiftStart + 135 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.pageMarginX + printer.printableWidth / 2 - 15,
    printer.currentPageY - tsize1.height
  );

  tsize = printer.printTextExt(
    "Извършил проверката:",
    printer.pageMarginX * 2 + printer.printableWidth / 2,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    printer.pageMarginX * 2 + printer.printableWidth / 2 + tsize.width + 5,
    printer.currentPageY - tsize.height,
    printer.printableWidth,
    printer.currentPageY - tsize.height
  );
  printer.withFont(
    () => {
      printer.doc.text(
        "(фамилия, подпис)",
        printer.pageMarginX * 2 +
        printer.printableWidth / 2 +
        tsize.width +
        2 +
        (printer.printableWidth -
          (printer.pageMarginX * 2 +
            printer.printableWidth / 2 +
            tsize.width +
            2)) /
        2,
        printer.currentPageY - tsize.height / 2,
        { align: "center" }
      );
    },
    "slab-light",
    8
  );

  printer.printSpace(-4);
  printer.doc.setLineDashPattern([], 0);
  printer.doc.line(
    printer.pageMarginX + printer.printableWidth / 2,
    line2Y,
    printer.pageMarginX + printer.printableWidth / 2,
    printer.currentPageY
  );
  printer.doc.line(
    printer.pageMarginX * 2,
    printer.currentPageY,
    printer.printableWidth,
    printer.currentPageY
  );

  const line3Y = printer.currentPageY;

  printer.printSpace(13);
  tsize = printer.printText("Километропоказател:", printer.pageMarginX * 2);
  tsize = printer.printTextExt(
    "Заредено гориво:",
    printer.pageMarginX * 2 + printer.printableWidth / 2,
    printer.currentPageY - tsize.height
  );

  printer.doc.setLineDashPattern([1, 3], 0);
  tsize = printer.printText("Начален:", printer.pageMarginX * 2);
  printer.doc.line(
    printer.pageMarginX * 2 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.pageMarginX * 2 + printer.printableWidth / 4 - 25,
    printer.currentPageY - tsize1.height
  );
  tsize = printer.printTextExt(
    "Краен:",
    printer.pageMarginX * 2 + printer.printableWidth / 4 - 10,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    printer.pageMarginX * 2 + printer.printableWidth / 4 - 10 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.pageMarginX + printer.printableWidth / 2 - 15,
    printer.currentPageY - tsize1.height
  );

  const lSize = printer.getWidth("л.");

  tsize = printer.printTextExt(
    "Първа смяна:",
    printer.pageMarginX * 2 + printer.printableWidth / 2,
    printer.currentPageY - tsize1.height
  );
  printer.doc.line(
    printer.pageMarginX * 2 + printer.printableWidth / 2 + tsize.width + 5,
    printer.currentPageY - tsize1.height,
    printer.pageMarginX * 2 +
    printer.printableWidth / 2 +
    printer.printableWidth / 4 -
    25 -
    lSize -
    3,
    printer.currentPageY - tsize1.height
  );
  printer.doc.text(
    "л.",
    printer.pageMarginX * 2 +
    printer.printableWidth / 2 +
    printer.printableWidth / 4 -
    25 -
    lSize,
    printer.currentPageY - tsize1.height
  );
  tsize = printer.printTextExt(
    "Втора смяна:",
    printer.pageMarginX * 2 +
    printer.printableWidth / 2 +
    printer.printableWidth / 4 -
    10,
    printer.currentPageY - tsize.height
  );
  printer.doc.line(
    printer.pageMarginX * 2 +
    printer.printableWidth / 2 +
    printer.printableWidth / 4 -
    10 +
    tsize.width +
    5,
    printer.currentPageY - tsize1.height,
    printer.printableWidth - lSize - 10,
    printer.currentPageY - tsize1.height
  );
  printer.doc.text(
    "л.",
    printer.printableWidth - lSize - 7,
    printer.currentPageY - tsize1.height
  );

  printer.printSpace(-8);
  printer.doc.setLineDashPattern([], 0);
  printer.doc.line(
    printer.pageMarginX + printer.printableWidth / 2,
    line3Y,
    printer.pageMarginX + printer.printableWidth / 2,
    printer.currentPageY
  );
  printer.doc.line(
    printer.pageMarginX * 2,
    printer.currentPageY,
    printer.printableWidth,
    printer.currentPageY
  );

  printer.printSpace(16);
  printer.doc.setLineDashPattern([1, 3], 0);
  tsize = printer.printText(
    "Приел и проверил пътния лист:",
    printer.pageMarginX * 2
  );
  printer.printTextExt(
    "/",
    printer.printableWidth - 275,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "/",
    printer.printableWidth - 125,
    printer.currentPageY - tsize.height
  );

  printer.doc.line(
    printer.pageMarginX * 2 + tsize.width + 5,
    printer.currentPageY - tsize.height,
    printer.printableWidth,
    printer.currentPageY - tsize.height
  );

  printer.withFont(
    () => {
      printer.doc.text(
        "(име, фамилия)",
        printer.pageMarginX * 2 +
        tsize.width +
        5 +
        (printer.printableWidth -
          275 -
          (printer.pageMarginX * 2 + tsize.width + 5)) /
        2,
        printer.currentPageY - tsize.height / 2,
        { align: "center" }
      );

      printer.doc.text(
        "(дата)",
        printer.printableWidth -
        275 +
        (printer.printableWidth - 125 - (printer.printableWidth - 275)) / 2,
        printer.currentPageY - tsize.height / 2,
        { align: "center" }
      );

      printer.doc.text(
        "(подпис)",
        printer.printableWidth -
        125 +
        (printer.printableWidth - (printer.printableWidth - 125)) / 2,
        printer.currentPageY - tsize.height / 2,
        { align: "center" }
      );
    },
    "slab-light",
    8
  );

  printer.printSpace(5);
  printer.withFont(
    () => {
      printer.printText(
        "ДОКУМЕНТИ ЗА ПРЕВОЗА КЪМ ПЪТНИЯ ЛИСТ",
        printer.pageMarginX + printer.printableWidth / 2,
        printer.printableWidth,
        { align: "center" }
      );
    },
    "slab-light",
    8,
    2
  );

  printer.doc.setLineDashPattern([], 0);
  printer.printSpace(-10);
  printer.autoTable({
    margin: {
      horizontal: printer.pageMarginX * 2,
      vertical: printer.pageMarginY,
    },
    styles: {
      halign: "left",
      valign: "middle",
      font: "Roboto",
      fontStyle: "slab-light" as FontStyle,
      fontSize: 10,
      lineColor: [0, 0, 0],
      lineWidth: 0,
      cellPadding: {
        horizontal: 6,
        top: 2,
        bottom: 0,
      },
      overflow: "ellipsize",
    },
    headStyles: {
      font: "Roboto",
      fontStyle: "slab-normal" as FontStyle,
      cellPadding: {
        horizontal: 6,
        vertical: 4,
      },
    },
    //columns: ["Вид обществен превоз", "Документи за превоза"],
    columns: [
      { header: { content: "Вид обществен превоз", colSpan: 1 } },
      { header: { content: "", colSpan: 1 } },
      { header: { content: "Документи за превоза", colSpan: 1 } },
      { header: { content: "", colSpan: 1 } },
      { header: { content: "", colSpan: 1 } },
      { header: { content: "", colSpan: 1 } },
    ],
    columnStyles: {
      0: {
        cellWidth: (printer.printableWidth - 2 * printer.pageMarginX) / 2 - 200,
      },
      1: {
        cellWidth: 200,
      },
      2: {
        cellWidth: (printer.printableWidth - 2 * printer.pageMarginX) / 2 - 230,
      },
      3: {
        cellWidth:
          (230 + 305 - (printer.printableWidth - 2 * printer.pageMarginX) / 2) /
          2 +
          35,
      },
      4: {
        cellWidth: (printer.printableWidth - 2 * printer.pageMarginX) / 2 - 305,
      },
      //5: {
      //  cellWidth:
      //    (230 + 305 - (printer.printableWidth - 2 * printer.pageMarginX) / 2) /
      //    2 - 35,
      //},
    },
    body: [
      [
        `1. Превоз по ${selectedTransport === "A"
          ? "автобусна "
          : selectedTransport === "TB"
            ? "тролейбусна "
            : selectedTransport === "TM"
              ? "трамвайна "
              : ""
        }линия №`,
        {
          content:
            page.documents[0] &&
              ((page.isSpecial && page.documents[0].shift === 3) ||
                (!page.isSpecial && page.documents[0].shift !== 3))
              ? page.documents[0].line
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "2. Разписание на линия №",
        {
          content:
            page.documents[0] &&
              ((page.isSpecial && page.documents[0].shift === 3) ||
                (!page.isSpecial && page.documents[0].shift !== 3))
              ? page.documents[0].line
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "кола по ред:",
        {
          content:
            page.documents[0] &&
              ((page.isSpecial && page.documents[0].shift === 3) ||
                (!page.isSpecial && page.documents[0].shift !== 3))
              ? page.documents[0].car
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
      ],
      [
        `3. Превоз по ${selectedTransport === "A"
          ? "автобусна "
          : selectedTransport === "TB"
            ? "тролейбусна "
            : selectedTransport === "TM"
              ? "трамвайна "
              : ""
        }линия №`,
        {
          content:
            page.documents[1] &&
              ((page.isSpecial && page.documents[1].shift === 3) ||
                (!page.isSpecial && page.documents[1].shift !== 3))
              ? page.documents[1].line
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "4. Разписание на линия №",
        {
          content:
            page.documents[1] &&
              ((page.isSpecial && page.documents[1].shift === 3) ||
                (!page.isSpecial && page.documents[1].shift !== 3))
              ? page.documents[1].line
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "кола по ред:",
        {
          content:
            page.documents[1] &&
              ((page.isSpecial && page.documents[1].shift === 3) ||
                (!page.isSpecial && page.documents[1].shift !== 3))
              ? page.documents[1].car
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
      ],
      [
        `5. Превоз по ${selectedTransport === "A"
          ? "автобусна "
          : selectedTransport === "TB"
            ? "тролейбусна "
            : selectedTransport === "TM"
              ? "трамвайна "
              : ""
        }линия №`,
        {
          content:
            page.documents[2] &&
              ((page.isSpecial && page.documents[2].shift === 3) ||
                (!page.isSpecial && page.documents[2].shift !== 3))
              ? page.documents[2].line
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "6. Разписание на линия №",
        {
          content:
            page.documents[2] &&
              ((page.isSpecial && page.documents[2].shift === 3) ||
                (!page.isSpecial && page.documents[2].shift !== 3))
              ? page.documents[2].line
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "кола по ред:",
        {
          content:
            page.documents[2] &&
              ((page.isSpecial && page.documents[2].shift === 3) ||
                (!page.isSpecial && page.documents[2].shift !== 3))
              ? page.documents[2].car
              : "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
      ],
      [
        "",
        {
          content: "",
          styles: { fontStyle: "slab-bold" as FontStyle, fontSize: 14 },
        },
        "",
        "",
        "",
        "",
      ],
    ],
    didDrawCell: (cell) => {
      //console.log(cell);
      // top
      cell.doc.line(
        cell.cell.x,
        cell.cell.y,
        cell.cell.x + cell.cell.width,
        cell.cell.y
      );
      // bottom
      cell.doc.line(
        cell.cell.x,
        cell.cell.y + cell.cell.height,
        cell.cell.x + cell.cell.width,
        cell.cell.y + cell.cell.height
      );

      if (cell.column.index === 0) {
        // left
        cell.doc.line(
          cell.cell.x,
          cell.cell.y,
          cell.cell.x,
          cell.cell.y + cell.cell.height
        );
      }

      if (cell.column.index === 1 || cell.column.index === 5) {
        // right
        cell.doc.line(
          cell.cell.x + cell.cell.width,
          cell.cell.y,
          cell.cell.x + cell.cell.width,
          cell.cell.y + cell.cell.height
        );
      }
    },
  });

  printer.printSpace(10);
  printer.withFont(
    () => {
      printer.printText(
        "Приложение 11 към чл.66 т.7/ДВ. бр.13 от 11.02.2011г.",
        printer.printableWidth,
        "unlimited",
        { align: "right" }
      );
    },
    "slab-light",
    9,
    1
  );

  printer.newPage();
}

function printCoursesTable(
  printer: ReportPrinter,
  printPowerConsumption: boolean,
  xOffset: number
) {
  printer.doc.setLineDashPattern([1, 3], 0);

  let tsize = printer.printText(
    "Направени курсове",
    printer.pageMarginX * 2 + 125 + xOffset,
    "unlimited",
    { align: "right" }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 130 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "бр.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  tsize = printer.printText(
    "От тях по разписание",
    printer.pageMarginX * 2 + 125 + xOffset,
    "unlimited",
    { align: "right" }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 130 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "бр.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  tsize = printer.printText(
    "Извън разписание",
    printer.pageMarginX * 2 + 125 + xOffset,
    "unlimited",
    { align: "right" }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 130 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "бр.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  tsize = printer.printText(
    "в движение",
    printer.pageMarginX * 2 + 125 + xOffset,
    "unlimited",
    { align: "right" }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 130 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "ч.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  tsize = printer.printText(
    "в престой",
    printer.pageMarginX * 2 + 125 + xOffset,
    "unlimited",
    {
      align: "right",
    }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 130 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "ч.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  printer.printText(
    "Изработено време:",
    printer.pageMarginX * 2 + 125 + xOffset,
    "unlimited",
    { align: "right" }
  );
  printer.resetY();
  tsize = printer.printText(
    "в резерва",
    printer.pageMarginX * 2 + 180 + xOffset,
    "unlimited",
    {
      align: "right",
    }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 187 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "ч.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  tsize = printer.printText(
    "в маневра",
    printer.pageMarginX * 2 + 180 + xOffset,
    "unlimited",
    {
      align: "right",
    }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 187 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "ч.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  tsize = printer.printText(
    "нощен труд",
    printer.pageMarginX * 2 + 180 + xOffset,
    "unlimited",
    { align: "right" }
  );
  printer.doc.line(
    printer.pageMarginX * 2 + 187 + xOffset,
    printer.currentPageY - tsize.height,
    printer.pageMarginX * 2 + 350 + xOffset,
    printer.currentPageY - tsize.height
  );
  printer.printTextExt(
    "ч.",
    printer.pageMarginX * 2 + 370 + xOffset,
    printer.currentPageY - tsize.height,
    "unlimited",
    { align: "right" }
  );

  if (printPowerConsumption) {
    tsize = printer.printText(
      "Електроенергия:",
      printer.pageMarginX * 2 + 125 + xOffset,
      "unlimited",
      { align: "right" }
    );
    printer.resetY();
    tsize = printer.printText(
      "икономия:",
      printer.pageMarginX * 2 + 185 + xOffset,
      "unlimited",
      {
        align: "right",
      }
    );
    printer.doc.line(
      printer.pageMarginX * 2 + 190 + xOffset,
      printer.currentPageY - tsize.height,
      printer.pageMarginX * 2 + 235 + xOffset,
      printer.currentPageY - tsize.height
    );

    printer.resetY();
    tsize = printer.printText(
      "преразход:",
      printer.pageMarginX * 2 + 295 + xOffset,
      "unlimited",
      {
        align: "right",
      }
    );
    printer.doc.line(
      printer.pageMarginX * 2 + 300 + xOffset,
      printer.currentPageY - tsize.height,
      printer.pageMarginX * 2 + 340 + xOffset,
      printer.currentPageY - tsize.height
    );

    printer.printTextExt(
      "kWh",
      printer.pageMarginX * 2 + 370 + xOffset,
      printer.currentPageY - tsize.height,
      "unlimited",
      { align: "right" }
    );

    printer.printSpace(8);
  } else {
    // printer.printSpace(20);
    printer.printSpace(8);
  }
  printer.printText(
    "Диспечер:",
    printer.pageMarginX * 2 + 25 + xOffset,
    "unlimited",
    {
      align: "left",
    }
  );
  printer.resetY();
  printer.printText(
    "Изчислител:",
    printer.pageMarginX * 2 + printer.printableWidth / 4 + xOffset,
    "unlimited",
    {
      align: "left",
    }
  );
}

async function printPageTwo(
  printer: ReportPrinter,
  page: PageData,
  garageSettings: GarageSettings
) {
  const printPowerConsumption =
    garageSettings.printPowerConsumption &&
    (!!page.vehicle?.includeEnergy || !!page.includeEnergyInEmpty);

  printer.autoTable({
    margin: {
      horizontal: printer.pageMarginX * 2,
      vertical: printer.pageMarginY * 1.5,
    },
    styles: {
      halign: "left",
      valign: "middle",
      font: "Roboto",
      fontStyle: "slab-light" as FontStyle,
      fontSize: 9,
      cellPadding: 4,
      lineColor: [0, 0, 0],
      lineWidth: 0.25,
    },
    headStyles: {
      halign: "center",
      font: "Roboto",
      fontStyle: "slab-normal" as FontStyle,
      fontSize: 8,
    },
    columnStyles: {
      0: { cellWidth: 105 },
      1: {},
      2: { cellWidth: 50 },
      3: { cellWidth: 60 },
      4: { cellWidth: 40 },
      5: { cellWidth: 105 },
      6: {},
      7: { cellWidth: 50 },
      8: { cellWidth: 60 },
      9: { cellWidth: 40 },
    },
    columns: [
      "Фамилия и № на водача\nизпълнител на превоза",
      "Маршрут\n(от - до)",
      "Тръгване\n(ч. мин.)",
      "Пристигане\n(ч. мин.)",
      "Заверка",
      "Фамилия и № на водача\nизпълнител на превоза",
      "Маршрут\n(от - до)",
      "Тръгване\n(ч. мин.)",
      "Пристигане\n(ч. мин.)",
      "Заверка",
    ],
    body: [
      ...new Array(
        garageSettings.printAdditionalTable && printPowerConsumption
          ? 13
          : garageSettings.printAdditionalTable && !printPowerConsumption
            ? 18
            : !garageSettings.printAdditionalTable && printPowerConsumption
              ? 22
              : 26
      ),
      // body: [
      //   ...new Array(
      //     selectedTransport === "TB" ? 13 : selectedTransport === "TM" ? 17 : 26
      //   ),
    ].map(() => [" ", " ", " ", " ", " ", " ", " ", " ", " ", " "]),
  });

  if (printPowerConsumption || garageSettings.printAdditionalTable) {
    if (printPowerConsumption) {
      printer.printSpace(10);
    }

    printer.autoTable({
      showHead: printPowerConsumption,
      margin: {
        horizontal: printer.pageMarginX * 2,
        vertical: printer.pageMarginY * 1.5,
      },
      styles: {
        halign: "left",
        valign: "middle",
        font: "Roboto",
        fontStyle: "slab-light" as FontStyle,
        fontSize: 9,
        cellPadding: 4,
        lineColor: [0, 0, 0],
        lineWidth: 0.25,
      },
      headStyles: {
        halign: "center",
        font: "Roboto",
        fontStyle: "slab-normal" as FontStyle,
        fontSize: 8,
      },
      columnStyles: {
        0: { cellWidth: 45 },
        1: { cellWidth: 70 },
        2: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        3: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        4: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        5: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        6: { cellWidth: 45 },
        7: { cellWidth: 70 },
        8: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        9: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        10: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
        11: {
          cellWidth:
            (printer.printableWidth -
              2 * printer.pageMarginX -
              2 * 45 -
              2 * 70) /
            8,
        },
      },
      columns: [
        "",
        "Показания на\nкилометража",
        "Ec\nконсумирана\nенергия",
        "Er\nвърната\nенергия",
        "Et\nенергия за\nотопление",
        "Eл\nенергия от\nдизела",
        " ",
        "Показания на\nкилометража",
        "Ec\nконсумирана\nенергия",
        "Er\nвърната\nенергия",
        "Et\nенергия за\nотопление",
        "Eл\nенергия от\nдизела",
      ],
      body: (printPowerConsumption
        ? [
          [
            "Начало",
            " ",
            " ",
            " ",
            " ",
            " ",
            "Начало",
            " ",
            " ",
            " ",
            " ",
            " ",
          ] as RowInput,
          [
            "Край",
            " ",
            " ",
            " ",
            " ",
            " ",
            "Край",
            " ",
            " ",
            " ",
            " ",
            " ",
          ] as RowInput,
        ]
        : []
      ).concat(
        garageSettings.printAdditionalTable
          ? [
            [
              {
                colSpan: 6,
                content: " ",
                styles: { minCellHeight: printPowerConsumption ? 180 : 168 },
              },
              {
                colSpan: 6,
                content: " ",
                styles: { minCellHeight: printPowerConsumption ? 180 : 168 },
              },
            ] as RowInput,
          ]
          : []
      ),
    });

    if (garageSettings.printAdditionalTable) {
      // const initialY = printer.currentPageY;
      printer.printSpace(printPowerConsumption ? -160 : -148);
      const startY = printer.currentPageY;
      printCoursesTable(printer, printPowerConsumption, 0);
      printer.setPosition(startY);
      printCoursesTable(
        printer,
        printPowerConsumption,
        printer.printableWidth / 2 - printer.pageMarginX
      );
      // printer.setPosition(initialY);
      printer.setPosition(printer.printableHeight - printer.pageMarginY);
    }
  }

  printer.printSpace(30);
  printer.doc.setLineDashPattern([1, 3], 0);
  const tsize = printer.printText(
    "Бележки на контролните органи:",
    printer.pageMarginX * 2
  );
  printer.doc.line(
    printer.pageMarginX * 2 + tsize.width + 5,
    printer.currentPageY - tsize.height,
    printer.printableWidth,
    printer.currentPageY - tsize.height
  );
  printer.withFont(
    () => {
      printer.doc.text(
        "(подпис и печат на контролните органи)",
        printer.printableWidth,
        printer.currentPageY - tsize.height / 2,
        { align: "right" }
      );
    },
    "slab-light",
    8
  );
}

function printSummary(
  printer: ReportPrinter,
  summary: JourneyListSummary[],
  page: PageData
) {
  printer.newPage();

  printer.setFont("bold", 12, 1.2);
  // printer.printSpace(12);
  printer.printText(
    page.depotName,
    printer.pageMarginX + printer.printableWidth / 2,
    "page",
    { align: "center" }
  );

  const tableStatus = {
    currentSide: 0,
    topMargin: printer.currentPageY,
  };

  printer.autoTable({
    //tableWidth: printer.printableWidth / 2 - 15,
    margin: {
      top: printer.pageMarginY,
      bottom: printer.pageMarginY,
      left: printer.pageMarginX,
      right: printer.pageMarginX + printer.printableWidth / 2 + 15,
    },
    styles: {
      valign: "middle",
      font: "Roboto",
      fontSize: 8,
      cellPadding: 4,
      lineColor: [0, 0, 0],
      lineWidth: 0.25,
    },
    columnStyles: {
      0: { cellWidth: 65, halign: "left" },
      1: { cellWidth: 35, halign: "left" },
      2: { cellWidth: 40, halign: "left" },
      3: { cellWidth: 40, halign: "left" },
      4: { halign: "right" },
    },
    head: [
      [
        { content: "Линия №", styles: { halign: "left" } },
        { content: "Кола", styles: { halign: "left" } },
        { content: "Смяна", styles: { halign: "left" } },
        { content: "Автобус", styles: { halign: "left" } },
        { content: "Пътен лист №", styles: { halign: "right" } },
      ],
    ],
    body: summary.map((s) => [
      { content: s.line },
      { content: s.car },
      { content: s.shift },
      { content: s.vehicle },
      { content: s.serial.substring(0, s.serial.indexOf(" ")) },
    ]),
    didDrawPage: (data) => {
      if (tableStatus.currentSide === 0) {
        data.settings.margin = {
          top: tableStatus.topMargin,
          bottom: printer.pageMarginY,
          left: printer.pageMarginX + printer.printableWidth / 2 + 15,
          right: printer.pageMarginX,
        };
        printer.doc.setPage(printer.doc.getCurrentPageInfo().pageNumber - 1);
        tableStatus.currentSide = 1;
        tableStatus.topMargin = printer.pageMarginY;
      } else {
        data.settings.margin = {
          top: tableStatus.topMargin,
          bottom: printer.pageMarginY,
          left: printer.pageMarginX,
          right: printer.pageMarginX + printer.printableWidth / 2 + 15,
        };
        tableStatus.currentSide = 0;
        tableStatus.topMargin = printer.pageMarginY;
      }
    },
  });

  printer.doc.deletePage(1);
}

async function printSingle(
  printer: ReportPrinter,
  page: PageData,
  selectedTransport: Transport,
  garageSettings: GarageSettings
) {
  await printPageOne(printer, page, selectedTransport);
  await printPageTwo(printer, page, garageSettings);
}

export function usePrintBlankJourneyList() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const selectedAutoColumn = useSelector(
    (state: RootState) => state.filters.selectedAutoColumn
  );
  const { depots, selectedDepot } = useDepotFilter();
  const { selectedTransport } = useTransportFilter();

  const processData = usePrintJourneyListData();
  const userGarageSettings = useGarageSettings();
  const garageSettings = getSettingsByGarage(selectedTransport, selectedDepot);

  const exportBlankPdf = useCallback(
    async (rosterDate: string, includePowerUsage?: boolean) => {
      if (selectedTransport === "") {
        setIsDone(true);
        setHasError(true);
        setIsLoading(false);
        throw "Transport is not selected";
      } else {
        setIsDone(false);
        setHasError(false);
        setIsLoading(true);

        try {
          const printer = new ReportPrinter({
            orientation: "landscape",
            printPageNumber: false,
            printDate: false,
            printMusat: false,
            marginX: 20,
            marginY: 20,
            lineSpacing: 1,
          });

          const data = await processData.emptyData({
            rosterDate,
            includeEnergyInEmpty: includePowerUsage,
          });

          await printSingle(printer, data, selectedTransport, garageSettings);

          printer.save(
            `Пътен-лист-${rosterDate}-${depots.find((d) => d.id === selectedDepot)?.value || "-"
            }-${selectedAutoColumn}-${moment().format(
              "YYYY-MM-DD-HH-mm-ss"
            )}.pdf`
          );

          return printer;
        } catch (err) {
          console.error("Cannot export into PDF:", err);
          setHasError(true);
          throw err;
        } finally {
          setIsDone(true);
          setIsLoading(false);
        }
      }
    },
    [
      depots,
      garageSettings,
      processData,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
    ]
  );

  const exportPdf = useCallback(
    async (
      vehicles: readonly RosterVehicle[],
      // drivers: readonly Driver[],
      drivers: readonly ScheduleDriver[],
      extraDrivers: ScheduleDriver[],
      //apiVehicles: readonly Vehicle[],
      shifts: readonly RosterShift[],
      rosterDate: string,
      includeEmptyVehicles?: boolean
    ) => {
      console.log("Export PDF");

      if (selectedTransport === "") {
        setIsDone(true);
        setHasError(true);
        setIsLoading(false);
        throw "Transport is not selected";
      } else {
        setIsDone(false);
        setHasError(false);
        setIsLoading(true);

        try {
          const data = await processData.processData({
            rosterDate: rosterDate,
            includeEmptyVehicles: !!includeEmptyVehicles,
            vehicles: vehicles,
            extraDrivers: extraDrivers,
            drivers: drivers,
            shifts: shifts,
          });

          const printer = new ReportPrinter({
            orientation: "landscape",
            printPageNumber: false,
            printDate: false,
            printMusat: false,
            marginX: 20,
            marginY: 20,
            lineSpacing: 1,
          });

          let isFirstPage = true;

          for (const page of data) {
            if (!isFirstPage) {
              printer.doc.addPage();
            }

            await printSingle(printer, page, selectedTransport, garageSettings);

            isFirstPage = false;
          }

          printer.save(
            `Пътен-лист-${rosterDate}-${depots.find((d) => d.id === selectedDepot)?.value || "-"
            }-${selectedAutoColumn}-${moment().format(
              "YYYY-MM-DD-HH-mm-ss"
            )}.pdf`
          );

          if (data.length > 0 && userGarageSettings.printJourneyListSummary) {
            // Печат на справка за пътните листа само за Дружба
            const rep_printer = new ReportPrinter({
              orientation: "portrait",
              printPageNumber: true,
              pageNumberOffset: -1,
              printDate: true,
              printMusat: true,
              marginX: 30,
              marginY: 50,
              lineSpacing: 1,
              headerText: `РАЗДАДЕНИ ПЪТНИ ЛИСТА НА НАРЯД ЗА ДАТА: ${moment(
                rosterDate,
                "YYYY-MM-DD"
              ).format("L")}`,
            });

            const summary = [...processJourneyListSummary(data)];
            printSummary(rep_printer, summary, data[0]);

            rep_printer.save(
              `Раздадени-пътни-листа-${rosterDate}-${depots.find((d) => d.id === selectedDepot)?.value || "-"
              }-${selectedAutoColumn}-${moment().format(
                "YYYY-MM-DD-HH-mm-ss"
              )}.pdf`
            );
          }

          return printer;
        } catch (err) {
          console.error("Cannot export into PDF:", err);
          setHasError(true);
          throw err;
        } finally {
          setIsDone(true);
          setIsLoading(false);
        }
      }
    },
    [
      depots,
      userGarageSettings.printJourneyListSummary,
      garageSettings,
      processData,
      selectedAutoColumn,
      selectedDepot,
      selectedTransport,
    ]
  );

  return {
    isLoading,
    isDone,
    hasError,
    exportPdf,
    exportBlankPdf,
  };
}
